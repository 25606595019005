<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="title-container">
        <h1>Que voulez vous manger ?</h1>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>