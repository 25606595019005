<!-- eslint-disable vue/multi-word-component-names -->
<template>
<div id="navbar">
  <ul class="menu-list">
    <li>
      <v-btn icon class="menu-btn" elevation="0" to="/">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
    </li>
    <li>
      <v-btn icon class="menu-btn" elevation="0" to="/la-carte">
        <v-icon>mdi-book-open-blank-variant</v-icon>
      </v-btn>
    </li>
    <li>
			<v-btn icon class="menu-btn" elevation="0" to="/ma-note">
        <v-badge color="#2e4057" v-if="nbSelected > 0" :content="nbSelected">
          <v-icon>mdi-script-text-outline</v-icon>
        </v-badge>

        <v-icon v-else>mdi-script-text-outline</v-icon>
			</v-btn>
		</li>
    <li>
			<v-btn icon class="menu-btn" elevation="0" to="/mon-compte">
				<v-icon>mdi-account</v-icon>
			</v-btn>
		</li>
  </ul>
</div>
</template>

<script>
import { store } from '@/store';

export default {
  data() {
    return {
    }
  },
  computed: {
    nbSelected() {
      return store.state.nbSelectedMeals
    },
  }
};
</script>

<style>

#navbar {
  width: 100%;
  height: 58px;
  display: flex;
  position: fixed;
  bottom: 2vh;
}


.menu-list {
  width: 92%;
  border-radius: 10px;
  background-color: white;
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-btn {
  color: lightgray;
}
.v-btn--active {
  color: #2e4057;
}

ul {
	width: 100%;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
}

li {
	padding: 5px;
	display: block;
	flex: 0 1 auto;
}
</style>
