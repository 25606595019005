import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import LaCarte from '../views/LaCarteView.vue'
import Article from '../views/ArticlePageView.vue'
import MaNote from '../views/MaNoteView.vue'
import Account from '../views/AccountView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/la-carte',
    name: 'la-carte',
    component: LaCarte
  },
  {
    path: '/ma-note',
    name: 'ma-note',
    component: MaNote
  },
  {
    path: '/mon-compte',
    name: 'mon-compte',
    component: Account
  },
  {
    path: '/article/:articleObject',
    name: 'article',
    component: Article,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
