<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="hello-container">
    <h2>👋 Hello {{ username }} !</h2>
  </div>
</template>

<script>
import { store } from "@/store";

export default {
  data() {
    return {
      username: store.state.username
    }
  },
};
</script>

<style></style>
