<template>
  <div class="carte-container">
    <Location/>
    <Hello/>
    <Title/>
    <Search @inputFilter="getInputFilter" />
    <Categories @chipsFilters="getChipsFilter"/>
    <AllMeals :inputFilter="this.inputFilter" :chipsFilter="this.chipsFilter" :dishes="this.dishes" @selectedMeal="getSelectedMeal"/>
  </div>
</template>

<script>
import Location from '@/components/Location.vue'
import Hello from '@/components/Hello.vue'
import Title from '@/components/Title.vue'
import Search from '@/components/Search.vue'
import Categories from '@/components/Categories.vue'
import AllMeals from '@/components/AllMeals.vue'
import firebaseApp from '@/firebaseConfig.js'
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { GetDishes } from '@/ToolsLibrary.js'

const db = getFirestore(firebaseApp)
const restaurant = doc(db, 'restaurants', 'TwcYGmDTROpZvClCTM1y')
const docSnap = await getDoc(restaurant);
const dishes = GetDishes(docSnap)


export default {
  components: { Location, Hello, Title, Search, Categories, AllMeals },
  data() {
    return {
      dishes: dishes,
      inputFilter: "",
      chipsFilter: [],
      selectedMeal: 0
    }
  },

  methods: {
    getInputFilter(filter) {
      this.inputFilter = filter
    },
    getChipsFilter(filter) {
      this.chipsFilter = filter
    },
    getSelectedMeal(count) {
      this.selectedMeal = count
    }
  },
}

</script>

<style>
.carte-container {
  width: 100%;
  height: auto;
  margin-bottom: 15vh;
}
</style>