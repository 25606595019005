<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="search-container">
        <input @input="searchFilter()" type="search" v-model="search" name="searchbar" id="searchbar" placeholder="Rechercher...">
    </div>
</template>

<script>
export default {
 data() {
  return {
    search: ""
  }
 },
	methods: {
		searchFilter() {
			this.$emit('inputFilter', this.search)
		}
	},
}
</script>

<style>

#searchbar {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 50px;
  padding-left: 30px;
  color: #2E4057;
  background-color: white;
  box-shadow: 0px 5px 20px #2e40572f;
}

#searchbar:focus-visible {
  outline: none
}

</style>