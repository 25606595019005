// Fonction qui permet de récupérer les "Dishes" issue d'un Document en BDD
function GetDishes(document)
{
  if (document.exists())
  {
    var data = document.data()
    return data.dishes
  }
}

export { GetDishes }