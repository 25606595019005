<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="card-container" v-if="filteredCard.length > 0">
    <div class="card" v-for="d in filteredCard" :key="d.id">
      <router-link
        :to="{ name: 'article', params: { articleObject: JSON.stringify(d) } }"
      >
        <img class="meal-img" :src="getUrl(d)" alt="meal-img" />
      </router-link>
      <h3 class="card-title">{{ d.name.split("").slice(0, 30).join("") }}</h3>
      <div class="card-bottom">
        <p class="price">{{ d.price.toFixed(2) }}€</p>
        <div class="add-container">
          <div v-if="d.nbSelected > 0" class="minus-count-container">
            <button class="minus-button" fab @click="decreaseMealCount(d)">
              <v-icon class="minus-icon" color="#2E4057" x-small>mdi-minus</v-icon>
            </button>
            <p class="meal-count">{{ d.nbSelected }}</p>
          </div>
          <button @click="displayMinus(d)" class="add-button">
            <v-icon class="plus-icon" color="#2E4057" x-small>mdi-plus</v-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h3>Aucun plat trouvé !</h3>
  </div>
</template>

<script>
import { store } from "@/store";
export default {
  props: ["filteredCard"],

  data() {
    return {
      minus: false,
      mealCount: 0,
      nbSelectedMeal: store.state.nbSelectedMeals,
      selectedMeals: store.state.selectedMeals,
      totalPrice: store.state.totalPrice
    };
  },

  

  methods: {
    displayMinus(obj) {
      obj.nbSelected++;
      this.nbSelectedMeal++;
      this.totalPrice = this.totalPrice + obj.price
      store.commit('increasePrice', obj.price)
      store.commit("increment", this.nbSelectedMeal);
      localStorage.setItem('totalPrice', this.totalPrice)
      localStorage.setItem(obj.id, obj.nbSelected);
      localStorage.setItem("nbTotalSelected", this.nbSelectedMeal);
      this.minus = true;
      store.commit("storeMeals", JSON.stringify(obj));
    },

    decreaseMealCount(obj) {
      obj.nbSelected--;
      this.nbSelectedMeal--;
      this.totalPrice = this.totalPrice - obj.price
      store.commit('decreasePrice', obj.price)
      store.commit("decrease", this.nbSelectedMeal);
      localStorage.setItem('totalPrice', this.totalPrice)
      localStorage.setItem(obj.id, obj.nbSelected);
      localStorage.setItem("nbTotalSelected", this.nbSelectedMeal);
      store.commit("unstoreMeals", JSON.stringify(obj));
      if (obj.nbSelected == 0) {
        this.minus = false;
      }
    },

    getUrl(d) {
      return d.url;
    },
  },
};
</script>

<style>
.card-container {
  display: flex;
  margin-bottom: 20px;
}

.card {
  min-width: 140px;
  max-width: 140px;
  height: 194px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #2e4057;
  box-shadow: 0px 5px 20px #2e40573b;
  color: white;
}

.model-url {
  display: flex;
  margin-bottom: 10px;
}

.card:nth-child(2n) {
  background-color: white;
}

.card:nth-child(2n) .card-title,
.card:nth-child(2n) .card-subtitle,
.card:nth-child(2n) p {
  color: #2e4057;
}

.model-url {
  width: 100%;
  height: auto;
}

.meal-img,
img.meal-img {
  width: 85%;
  height: auto;
  display: flex;
  margin: 0px auto;
}

h3.card-title {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  height: 50px;
  color: white;
  font-weight: bold;
  font-size: 0.95em;
}

.card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  margin: 0px;
  font-weight: 300;
  font-size: 1em;
  color: white;
}

.add-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.add-button,
.minus-button {
  background-color: #93b1d7;
  border-radius: 100px;
  width: 25px;
  height: 25px;
}

.card:nth-child(2n) .add-button,
.card:nth-child(2n) .minus-button {
  background-color: #2e4057;
}

.card:nth-child(2n) .mdi-plus::before,
.card:nth-child(2n) .mdi-minus::before {
  color: #f5f9ff;
}

.plus-icon,
.minus-icon {
  width: 10px;
}

.minus-count-container {
  display: flex;
  align-items: center;
}

.meal-count {
  margin: 0px 3px;
  color: white;
  font-weight: 300;
  font-size: 1em;
}
</style>
