<template>
<div class="bill-container" v-if="filteredCard.length > 0">
    <div class="bill-card-container" v-for="m in filteredCard" :key="m.id">
      <div class="bill-card">
        <router-link class="link" :to="{name: 'article', params: { articleObject: JSON.stringify(m) }}">
          <img class="bill-meal-img" :src="getUrl(m)" alt="meal-img">
        </router-link>
        <div class="bill-meal-info">
          <h3 class="bill-card-title">{{ m.name.split("").slice(0, 30).join("") }}</h3>
          <div class="card-bottom bill-card-bottom">
          <p class="price bill-price">{{ m.price.toFixed(2) }}€</p>
          <div class="add-container">
            <div v-if="m.nbSelected > 0" class="minus-count-container">
              <button class="minus-button" fab @click="decreaseMealCount(m)">
                <v-icon class="minus-icon" color="#2E4057" x-small>mdi-minus</v-icon>
              </button>
              <p class="meal-count">{{ m.nbSelected }}</p>
            </div>
            <button @click="displayMinus(m)" class="add-button">
              <v-icon class="plus-icon" color="#2E4057" x-small>mdi-plus</v-icon>
            </button>
          </div>
        </div>
        </div>
      </div>
      <div class="remove-all-btn" @click="removeMeal(m)">
        <v-icon color="white">mdi-trash-can-outline</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '@/store';
export default {
  data() {
    return {
      meal: JSON.parse(localStorage.getItem('selectedMeals')),
      nbSelectedMeal: store.state.nbSelectedMeals,
      totalPrice: store.state.totalPrice
    }
  },
  computed: {
    filteredCard() {
      let filtered = this.meal
      if (this.meal.length > 0) {
        for (let i = 0; i < this.meal.length; i++) {
          filtered = this.meal.filter(i => i.nbSelected > 0)
        }
        return filtered
      } else {
        return filtered
      }
      }
  },
  methods: {
    getUrl(m) {
        return m.url;
      },

    displayMinus(obj) {
      obj.nbSelected++
      this.nbSelectedMeal++
      this.totalPrice = this.totalPrice + obj.price
      store.commit('increasePrice', obj.price)
      localStorage.setItem('totalPrice', this.totalPrice)
      this.$emit('totalPrice', this.totalPrice)
      store.commit('increment', this.nbSelectedMeal)
      localStorage.setItem(obj.id, obj.nbSelected)
      localStorage.setItem('nbTotalSelected', this.nbSelectedMeal)
      this.minus = true;
      store.commit('storeMeals', JSON.stringify(obj))
    },

    decreaseMealCount(obj) {
      obj.nbSelected--
      this.nbSelectedMeal--
      this.totalPrice = this.totalPrice - obj.price
      store.commit('decreasePrice', obj.price)
      localStorage.setItem('totalPrice', this.totalPrice)
      this.$emit('totalPrice', this.totalPrice)
      store.commit('decrease', this.nbSelectedMeal)
      localStorage.setItem(obj.id, obj.nbSelected)
      localStorage.setItem('nbTotalSelected', this.nbSelectedMeal)
      store.commit('unstoreMeals', JSON.stringify(obj))
      if (obj.nbSelected == 0) {
        this.meal = JSON.parse(localStorage.getItem('selectedMeals'))
        this.minus = false;
        localStorage.removeItem(obj.id)
      }
    },

    removeMeal(obj) {
      this.nbSelectedMeal = this.nbSelectedMeal - obj.nbSelected
      localStorage.setItem('nbTotalSelected', this.nbSelectedMeal)
      store.commit('decrease', this.nbSelectedMeal)
      store.commit('decreasePrice', obj.price * obj.nbSelected)
      this.totalPrice = this.totalPrice - obj.price * obj.nbSelected;
      this.$emit('totalPrice', this.totalPrice)
      localStorage.setItem('totalPrice', this.totalPrice)
      obj.nbSelected = 0
      localStorage.setItem(obj.id, obj.nbSelected)
      store.commit('unstoreMeals', JSON.stringify(obj))
      if (this.nbSelectedMeal === 0) {
        this.meal = JSON.parse(localStorage.getItem('selectedMeals'))
        localStorage.removeItem(obj.id)
      }
    },
}


}
</script>

<style>

.bill-container {
  display: flex;
  flex-direction: column;
}

.bill-card-container {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.bill-card {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  background-color: #2e4057;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 5px 20px #2e40573b;
}

.bill-meal-img {
  width: 100%;
}

.link {
  width: 25%;
  align-self: center;
}

.bill-card-title {
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

.bill-price {
  font-size: 1.5em;
}

.remove-all-btn {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  border-radius: 10px;
  background-color: #EE4444;
  box-shadow: 0px 5px 20px #ee444450;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>