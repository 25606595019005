<template>
  <div class="profile-container">
    <div class="profile-info-container">
      <div class="avatar-container">
        <v-avatar size="125" image="https://card-lion.b-cdn.net/users/contact_pictures/68624245205__01F4063C-508A-447D-A2B7-9DB4405C0174.jpeg"></v-avatar>
      </div>

      <div class="profile-name-container">
        <h2 class="name">{{ this.username }}</h2>
        <div class="sucess-rank">
          <v-icon color="#2E4057" size="20">mdi-medal</v-icon>
          <p class="account-latest-success">Rookie de l'AR</p>
        </div>
      </div>
    </div>

    <div class="settings-container">
        <div class="edit-profile-row setting-card">
          <v-icon size="35" class="account-edit-icon" color="#2E4057">mdi-account-edit</v-icon>
          <div class="setting-label-container">
            <h2 class="setting-label">Éditer mon profile</h2>
          </div>
        </div>

        <div class="my-favorites-row setting-card">
          <v-icon size="35" class="account-edit-icon setting-icon" color="#2E4057">mdi-heart</v-icon>
          <div class="setting-label-container">
            <h2 class="setting-label">Mes favoris</h2>
          </div>
        </div>

        <div class="my-orders-row setting-card">
          <v-icon size="35" class="account-edit-icon" color="#2E4057">mdi-script-text</v-icon>
          <div class="setting-label-container">
            <h2 class="setting-label">Mes commandes</h2>
          </div>
        </div>

        <div class="my-success-row setting-card">
          <v-icon size="35" class="account-edit-icon" color="#2E4057">mdi-medal</v-icon>
          <div class="setting-label-container">
            <h2 class="setting-label">Mes succès</h2>
          </div>
        </div>

        <div class="my-notifications-row setting-card">
          <v-icon size="35" class="account-edit-icon" color="#2E4057">mdi-bell</v-icon>
          <div class="setting-label-container">
            <h2 class="setting-label">Notifications</h2>
          </div>
        </div>

        <div class="contact-us-row setting-card">
          <v-icon size="35" class="account-edit-icon" color="#2E4057">mdi-email</v-icon>
          <div class="setting-label-container">
            <h2 class="setting-label">Nous contacter</h2>
          </div>
        </div>

        <div class="delete-account-row setting-card">
          <v-icon size="35" class="account-edit-icon" color="#2E4057">mdi-trash-can</v-icon>
          <div class="setting-label-container">
            <h2 class="setting-label">Supprimer le compte</h2>
          </div>
        </div>

        <div class="user-conditions-row setting-card">
          <v-icon size="35" class="account-edit-icon" color="#2E4057">mdi-playlist-check</v-icon>
          <div class="setting-label-container">
            <h2 class="setting-label">Conditions générales d'utilisation</h2>
          </div>
        </div>

        <div class="data-protection-row setting-card">
          <v-icon size="35" class="account-edit-icon" color="#2E4057">mdi-shield-check</v-icon>
          <div class="setting-label-container">
            <h2 class="setting-label">Protection des données et vie privée</h2>
          </div>
        </div>
        <div class="logout-container">
          <v-btn variant="text" color="#EE4444" @click="logout">Se déconnecter</v-btn>
        </div>
      </div>
  </div>
  
</template>

<script>
import { store } from "@/store";

export default {
  data() {
    return {
      username: store.state.username,
    }
  },

  methods: {
    logout() {
      store.commit('logout')
    }
  },
}
  
</script>

<style>

.profile-container {
  width: 100%;
}

.settings-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 100px;
}

.account-container, .profile-info-container {
  display: flex;
  flex-direction: column;
}

.profile-info-container {
  margin-bottom: 50px;
}

.account-latest-success {
  font-weight: 300;
}

.avatar-container {
  display: flex;
  justify-content: center;
  margin: 30px 0px 20px 0px
}

.profile-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sucess-rank {
  display: flex;
  align-items: center;
}

.setting-category {
  font-weight: 300;
}

.setting-card {
  width: 100px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 20px #2e40573b;
}

.setting-label-container {
  width: 100px;
  height: 50px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.setting-label {
  font-size: 1em;
}

.logout-container {
  margin: 20px auto;
}

.logout-container h2 {
  color: #EE4444;
}
</style>