import { createStore } from "vuex";

export const store = createStore({
  state() {
    return {
      nbSelectedMeals: localStorage.getItem('nbTotalSelected'),
      selectedMeals: [],
      totalPrice: Number(localStorage.getItem('totalPrice')),
      logged: localStorage.getItem('logged'),
      username: localStorage.getItem('username')
    };
  },
  mutations: {
    increment (state, n) {
      state.nbSelectedMeals = n
    },

    decrease (state, n) {
      state.nbSelectedMeals = n
    },

    increasePrice(state, n) {
      state.totalPrice+= n
    },

    decreasePrice(state, n) {
      state.totalPrice-= n
    },

    storeMeals (state, obj) {
      let meal = JSON.parse(obj)
      if (state.selectedMeals.length <= 0) {
        state.selectedMeals.push(meal)
        localStorage.setItem('selectedMeals', JSON.stringify(state.selectedMeals))
      } else {
        let index = state.selectedMeals.findIndex(i => i.id === meal.id)
        if (index !== -1) {
          state.selectedMeals.splice(index, 1)
          state.selectedMeals.push(meal)
          localStorage.setItem('selectedMeals', JSON.stringify(state.selectedMeals))
        } else {
          state.selectedMeals.push(meal)
          localStorage.setItem('selectedMeals', JSON.stringify(state.selectedMeals))
        }
      }
    },

    unstoreMeals(state, obj) {
      let meal = JSON.parse(obj)
      state.selectedMeals = JSON.parse(localStorage.getItem('selectedMeals'))
      let index = state.selectedMeals.findIndex(i => i.id === meal.id)
      if (meal.nbSelected === 0) {
        state.selectedMeals.splice(index, 1)
        localStorage.setItem('selectedMeals', JSON.stringify(state.selectedMeals))
      } else {
        state.selectedMeals.splice(index, 1)
        state.selectedMeals.push(meal)
        localStorage.setItem('selectedMeals', JSON.stringify(state.selectedMeals))
      }
    },

    logout (state) {
      state.logged = false
      localStorage.removeItem('username')
      localStorage.removeItem('logged')
      window.location.reload()
    }
  }
});