<template>
<div class="container">
    <Location/>
    <div class="bill-header">
      <div class="empty-bill" v-if="nbSelected <= 0">
        <h1>Votre note est vide !</h1>
        <p>Ajoutez des plats pour les voir ici.</p>
      </div>
      <div v-if="nbSelected > 0">
        <h1 class="bill-title">Votre note :</h1>
      </div>
    </div>
    <div class="meal-list" v-if="nbSelected > 0">
      <BillCard @totalPrice="updateTotalPrice"/>
    </div>
    <div class="resume-order-container" v-if="nbSelected > 0">
      <div class="total-amount-container">
        <h2>Total :</h2>
        <p>{{ this.updatedPrice}}€</p>
      </div>
      <div class="order-all-container">
        <v-btn class="blue-btn order-all">
          <p class="order-btn-txt">Commander</p>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '@/store'
import Location from '../components/Location.vue'
import BillCard from '../components/BillCard.vue'

   export default {
    components: {
      Location,
      BillCard
    },
    data() {
      return {
        totalPrice: store.state.totalPrice,
        updatedPrice: store.state.totalPrice
      }
    },
  computed: {
    nbSelected() {
      return store.state.nbSelectedMeals
    },

    selectedMeals() {
      return store.state.selectedMeals
    },
  },
   methods: {
    updateTotalPrice(price) {
      this.updatedPrice = price
      store.commit('totalPrice', this.updatedPrice)
    }
   },
   }
</script>

<style>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bill-title {
  margin-bottom: 25px;
}

.meal-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
}

.resume-order-container {
  width: 92%;
  height: 60px;
  border-radius: 10px;
  background-color: white;
  position: fixed;
  bottom:80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.total-amount-container {
  width: 40%;
  display: flex;
  align-items: center;
}

.total-amount-container p {
  font-size: 1.3em;
  font-weight: 300;
  margin-left: 5px;
}

.order-all-container {
  width: 40%;
}

.order-all {
  width: 100%;
}
</style>