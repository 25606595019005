<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="all-meals-container">
    <div class="all-meals-title">
      <h2>La carte :</h2>
    </div>

    <div class="all-meals-container" v-if="this.chipsFilter.length <= 0">
      <div class="starter-container">
        <h2 class="meal-type">Les entrées</h2>
        <div class="starter-cards">
          <div class="all-items">
            <Card :filteredCard="this.filteredStarters" @selectedMeal="getSelectedMeal"/>
          </div>
        </div>
      </div>

      <div class="starter-container">
        <h2 class="meal-type">Les plats</h2>
        <div class="starter-cards">
          <div class="all-items">
            <Card :filteredCard="this.filteredMain" @selectedMeal="getSelectedMeal"/>
          </div>
        </div>
      </div>

      <div class="starter-container">
        <h2 class="meal-type">Les desserts</h2>
        <div class="starter-cards">
          <div class="all-items">
            <Card :filteredCard="this.filteredDesserts" @selectedMeal="getSelectedMeal"/>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <Card :filteredCard="this.filteredCard" @selectedMeal="getSelectedMeal"/>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue';

export default {
  props: ["inputFilter", "chipsFilter", "dishes"],

  data() {
    return {
      meals: this.dishes,
      selectedMeal: 0
    };
  },

  methods: {

    getUrl(d) {
      return d.url;
    },

    getModelAndroid(d) {
      return d.modelAndroid;
    },

    getModelIos(d) {
      return d.modelIos;
    },

    getSelectedMeal(count) {
      this.selectedMeal = count
      this.$emit('selectedMeal', this.selectedMeal)
    }
  },

  computed: {
    filteredCard() {
      let filtered = this.meals

      for (let i = 0; i < filtered.length; i++) {
        filtered[i].nbSelected = localStorage.getItem(this.meals[i].id);
      }

      if (this.inputFilter.trim().length > 0) {
        filtered = this.meals.filter(
          (d) =>
            d.name.toLowerCase().includes(this.inputFilter.trim().toLowerCase()) ||
            d.categories
              .toString()
              .toLowerCase()
              .includes(this.inputFilter.trim().toLowerCase())
        );
        return filtered;
      }

      if (this.chipsFilter.length > 0) {
        filtered = this.meals.filter((d) =>
          d.categories.some((item) => this.chipsFilter.includes(item))
        );
      }

      return filtered
    },

    filteredStarters() {
      let filtered = this.meals.filter((d) => d.type === 0);
      return filtered;
    },

    filteredMain() {
      let filtered = this.meals.filter((d) => d.type === 1);
      return filtered;
    },

    filteredDesserts() {
      let filtered = this.meals.filter((d) => d.type === 2);
      return filtered;
    }
  },

  components: {
    Card
  },
};
</script>

<style>
.all-meals-container {
  width: 100%;
}

.all-meals-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
}

.all-items {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.all-items::-webkit-scrollbar {
  display: none;
}

.meal-type {
  font-size: 1em;
  margin-bottom: 20px;
}


.card:nth-child(2n) {
  background-color: white;
  color: #2e4057;
}

.card-title {
  padding: 0px;
  margin: 0px;
}
</style>
