import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCtnExWMv7x_jgvdb3uDI0tmSpED_x3GQU",
  authDomain: "card-2c554.firebaseapp.com",
  projectId: "card-2c554",
  storageBucket: "card-2c554.appspot.com",
  messagingSenderId: "96862784943",
  appId: "1:96862784943:web:1675ffaea6387fd272335a",
  measurementId: "G-2WHSS6R3NN"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export default firebaseApp;