<template>
  <div class="article-page-container">
    <div class="header">
      <v-btn icon class="back-btn" elevation="0" to="/">
        <v-icon class="back-icon" color="#2e4057">mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon class="love-btn" elevation="0">
        <v-icon class="heart-icon" color="#2e4057">mdi-heart-outline</v-icon>
      </v-btn>
    </div>
    <div class="article-info-container">
      <h1 class="meal-name">{{ this.article.name }}</h1>
      <v-rating
        class="rating"
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        half-icon="mdi-star-half-full"
        color="#2e4057"
        length="5"
        hover
        size="25"
      ></v-rating>
      <div class="price-container">
        <h3 class="price-article">{{ this.article.price.toFixed(2) }}€</h3>
      </div>
    </div>
    <div class="meal-img-container">
      <model-viewer
        class="model-viewer"
        :ios-src="this.article.modelIos"
        :src="this.article.modelAndroid"
        :poster="this.article.url"
        alt="3D model of a meal"
        ar
        camera-controls
      >
        <v-btn slot="ar-button" class="ar-button blue-btn">
          <p class="ar-button-txt">Visualiser</p>
          <v-icon color="white">mdi-cube-outline</v-icon>
        </v-btn>
      </model-viewer>
    </div>
    <div class="meal-description-container">
      <div class="ingredients-container">
        <h2>Ingrédients :</h2>
        <ul class="ingredients-list">
          <li v-for="i in this.article.ingredients" :key="i">
            <p class="ingredients-txt">• {{ i }}</p>
          </li>
        </ul>
      </div>
      <div class="meal-description">
        <h2>Description :</h2>
        <p class="meal-description-txt">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem, veniam
          sit incidunt tenetur dolores iste? Nemo modi, illum esse recusandae atque
          provident commodi. Veniam excepturi voluptatum odio similique neque.
        </p>
      </div>
    </div>

    <div class="add-tag">
      <div class="add-container-article">
        <div v-if="this.article.nbSelected > 0" class="minus-count-container">
          <button class="minus-button-article" fab @click="decreaseMealCount(this.article)">
            <v-icon class="minus-icon" color="white" x-small>mdi-minus</v-icon>
          </button>
          <p class="meal-count-article">{{ this.article.nbSelected }}</p>
          <button
            v-if="this.minus == true"
            @click="displayMinus(this.article)"
            class="add-button-article"
          >
            <v-icon class="plus-icon" color="white" x-small>mdi-plus</v-icon>
          </button>
        </div>
        <v-btn class="blue-btn order" @click="displayMinus(this.article)" v-if="this.minus == false">
          <p class="order-btn-txt">Commander</p>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store";

export default {
  props: ["articleObject"],
  data() {
    return {
      article: JSON.parse(this.articleObject),
      minus: false,
      selectedMeal: store.state.nbSelectedMeals,
      totalPrice: store.state.totalPrice
    };
  },

  mounted() {
    this.article.nbSelected = localStorage.getItem(this.article.id);
    if (this.article.nbSelected > 0) {
      this.minus = true;
    }
  },

  methods: {
    displayMinus(obj) {
      this.article.nbSelected++;
      this.selectedMeal++;
      this.totalPrice+= obj.price
      store.commit('increasePrice', obj.price)
      store.commit("increment", this.selectedMeal);
      localStorage.setItem("nbTotalSelected", this.selectedMeal);
      localStorage.setItem(obj.id, obj.nbSelected);
      this.minus = true;
      store.commit('storeMeals', JSON.stringify(obj))
    },

    decreaseMealCount(obj) {
      this.article.nbSelected--;
      this.selectedMeal--;
      this.totalPrice-= obj.price
      store.commit('decreasePrice', this.totalPrice)
      localStorage.setItem('totalPrice', this.totalPrice)
      store.commit("decrease", this.selectedMeal);
      localStorage.setItem("nbTotalSelected", this.selectedMeal);
      localStorage.setItem(obj.id, obj.nbSelected);
      store.commit('unstoreMeals', JSON.stringify(obj))
      if (obj.nbSelected == 0) {
        this.minus = false;
      }
    },

    getModelAndroid(d) {
      return d.modelAndroid;
    },

    getModelIos(d) {
      return d.modelIos;
    },
  },
};
</script>

<style>
.article-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25vh;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.back-btn,
.love-btn {
  background-color: #f5f9ff;
}

.article-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.meal-img-container {
  width: 100%;
  display: flex;
  height: 35vh;
  flex-direction: column;
}

.model-viewer {
  width: 100%;
  height: 100%;
}

.ar-button {
  position: absolute;
  bottom: 0px;
  left: 15vh;
}

.ar-button-txt,
.order-btn-txt {
  color: white;
  margin-right: 5px;
  font-weight: 300;
}

.order-btn-txt {
  margin: 0px;
}

.ingredients-container {
  margin-bottom: 20px;
}

.ingredients-list {
  display: flex;
  flex-direction: column;
}

.ingredients-list li {
  padding: 0px;
}

.ingredients-txt,
.meal-description-txt,
.meal-categorie-txt,
.meal-count-article {
  font-weight: 300;
}

.meal-count-article {
  color: #2e4157;
  margin: 0px 10px;
}

.add-tag {
  width: 92%;
  position: fixed;
  bottom: 85px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order {
  width: 100%;
}

.price-container {
  width: 50%;
  display: flex;
}

.price-article {
  font-weight: 500;
  font-size: 2em;
  margin: 0px auto;
}

.add-container-article {
  background: white;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
}

.add-button-article,
.minus-button-article {
  background-color: #2e4157;
  border-radius: 100px;
  width: 35px;
  height: 35px;
}
</style>
