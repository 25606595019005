<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-chip-group multiple light active-class="primary--text">
    <v-chip
      color="#01579B"
      @click="chipsClicked(c.text)"
      v-for="c in categories"
      :key="c.id"
      outlined
      text-color="#252525"
      >{{ c.text }}</v-chip
    >
  </v-chip-group>
</template>

<script>
import Categories from "@/dynamic/categories.json";

export default {
  data() {
    return {
      categories: Categories,
      chipsFilters: [],
    };
  },
  methods: {
    chipsClicked(value) {
      let index = this.chipsFilters.indexOf(value);
      if (this.chipsFilters.includes(value)) {
        this.chipsFilters.splice(index, 1);
      } else {
        this.chipsFilters.push(value);
      }
      this.$emit('chipsFilters', this.chipsFilters)
    },
  },
};
</script>

<style>
.chip-background {
  width: auto;
  height: 30px;
  background-color: white;
}

.chip-text {
  width: auto;
  height: auto;
}
</style>
