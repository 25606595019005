<template>
  <div id="app-container">
    <div class="logged" v-if="logged">
      <router-view></router-view>
      <Menu />
    </div>
    <div id="login-page" v-else>
      <Login @logged="getLogged" />
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Login from "@/views/LoginView.vue";

export default {
  data() {
    return {
      logged: localStorage.getItem('logged'),
    };
  },

  computed: {
  },

  methods: {
    getLogged(status) {
      if (status == true) {
        this.logged = true
      } else {
        alert("Vous n'êtes pas connecté.")
      }
    },
  },
  components: {
    Menu,
    Login,
  },
  name: "App",
};
</script>

<style>
@font-face {
  font-family: "Roboto";
  font-display: auto;
  src: local("Roboto"), url("../public/fonts/Roboto-Regular-webfont.woff"), format("woff");
}

html,
body {
  font-family: "Roboto", Helvetica, sans-serif;
  width: 100%;
  height: 100%;
  background-color: #e7f0fd;
}

#app {
  width: 100%;
  height: 90vh;
}

#app-container {
  width: 100%;
  padding: 15px;
  background-color: #e7f0fd;
  display: flex;
  justify-content: center;
}

.logged {
  width: 100%;
}

#login-page {
  width: 100%;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5 {
  color: #2e4057;
  margin: 0px;
}

h3 {
  font-weight: 200;
  font-size: 1.3em;
}

h2 {
  font-weight: Bold;
  font-size: 1.3em;
}

h1 {
  font-weight: bolder;
  font-size: 1.7em;
}

p {
  font-weight: 200;
  color: #2e4057;
  font-size: 1.2em;
}

.hello-container,
.title-container,
#searchbar,
.categories-items {
  margin-bottom: 25px;
}

a {
  font-size: 0.8em;
  color: #2e4057;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #2e4057;
}

.blue-btn {
  background: #2e4057;
  border-radius: 28px;
}

.blue-btn p {
  color: white;
}
</style>
