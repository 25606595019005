<template>
  <div id="login-container">
    <div class="app-title-container">
      <h1 class="app-title">CARD</h1>
    </div>

    <div class="login-inputs-container">
      <v-form v-model="form" @submit.prevent="onSubmit">
        <v-text-field
          v-model="username"
          :readonly="loading"
          :rules="[required]"
          class="username-input"
          clearable
          label="Identifiant"
          hint="Par exemple : Batman"
          color="#2E4057"
          type="text"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :readonly="loading"
          :rules="[required]"
          class="password-input"
          clearable
          label="Mot de passe"
          hint='Surtout pas "1234" !'
          color="#2E4057"
          type="password"
        ></v-text-field>
        <v-btn class="login-button"
          :disabled="!form"
          :loading="loading"
          block
          color="#2E4057"
          size="large"
          type="submit"
          variant="elevated"
          @click="load"
        >
          <span>Se connecter</span>
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: false,
      username: null,
      password: null,
      loading: false,
      logged: false
    }
  },

  methods: {
      onSubmit() {
        if (!this.form) {
          console.log('coucou');
          this.loading = true
          setTimeout(() => (this.loading = false), 2000)
        }
      },

      load() {
        this.loading = true
        this.logged = true
        setTimeout(() => {
          this.$emit('logged', this.logged)
        localStorage.setItem('username', this.username)
        localStorage.setItem('logged', this.logged)
        this.form = true
        this.loading = false
        window.location.reload()
        }, 2000);
      },

      required (v) {
        return !!v || 'Ce champ est obligatoire'
      },
    },
}
</script>

<style>

#login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-title-container {
  margin-bottom: 20px;
}

.app-title {
  font-size: 4em;
}

.login-inputs-container {
  width: 100%;
}

.username-input, .password-input, .login-button {
  margin-bottom: 10px;
}

.login-button span {
  color: white;
}

</style>