<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="categories-container">
    <div class="categories-title">
      <h2>Catégories :</h2>
    </div>
    <div class="categories-items">
      <Chips @chipsFilters="updateFilters"/>
    </div>
  </div>
</template>

<script>
import Chips from "./Chips.vue";
export default {
  components: {
    Chips,
  },
  data() {
    return {
		};
  },
  methods: {
		updateFilters(clickedChips) {
			this.$emit('chipsFilters', clickedChips)
		}
  },
};
</script>

<style>
</style>
