<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="best-container">
    <div class="best-title">
      <h2>Les valeurs sûres :</h2>
      <a class="see-all" href="/la-carte">Voir tout</a>
    </div>
    <div class="best-items" >
      <Card :filteredCard="this.filteredCard" @selectedMeal="getSelectedMeal"/>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue';

export default {
  props: ["inputFilter", "chipsFilter", "dishes"],

  data() {
    return {
      meals: this.dishes.filter((d) => d.isBest),
      selectedMeal: 0
    };
  },
  methods: {

    getUrl(d) {
      return d.url;
    },

    getModelAndroid(d) {
      return d.modelAndroid;
    },

    getModelIos(d) {
      return d.modelIos;
    },

    getSelectedMeal(count) {
      this.selectedMeal = count
      this.$emit('selectedMeal', this.selectedMeal)
    }
  },
  computed: {
    filteredCard() {
      let filtered = this.meals;

      for (let i = 0; i < filtered.length; i++) {
        filtered[i].nbSelected = localStorage.getItem(this.meals[i].id);
      }

      if (this.inputFilter.trim().length > 0) {
        filtered = this.meals.filter(
          (d) =>
            d.name.toLowerCase().includes(this.inputFilter.trim().toLowerCase()) ||
            d.categories
              .toString()
              .toLowerCase()
              .includes(this.inputFilter.trim().toLowerCase())
        );
        return filtered;
      }
      if (this.chipsFilter.length > 0) {
        filtered = this.meals.filter((d) =>
          d.categories.some((item) => this.chipsFilter.includes(item))
        );
      }
      return filtered;
    },
  },

  components: {
    Card
  },
};
</script>

<style>
.best-container {
  width: 100%;
}

.best-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 25px;
}

.best-items {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.best-items::-webkit-scrollbar {
  display: none;
}

.card:nth-child(2n) {
  background-color: white;
  color: #2e4057;
}

.card-title {
  padding: 0px;
  margin: 0px;
}

.see-all {
  font-size: 0.8em;
  color: #2e4057;
}

.see-all:hover,
.see-all:focus {
  text-decoration: none;
  color: #2e4057;
}
</style>
