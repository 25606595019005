<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="location-container">
        <v-icon class="location-icon" color="#2e4057" size="small">mdi-map-marker-outline</v-icon>
        <h3 class="restaurant-name">Le resto</h3>
    </div>
</template>

<script>
export default {

    }
</script>

<style>

.location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 35px 0px;
}

.restaurant-name {
    font-weight: 300;
}
</style>